import React from 'react';
import {Link, PageProps} from 'gatsby';
import Layout from '@/components/Layout';
import {OutboundLink} from 'gatsby-plugin-google-analytics';

const About: React.FC<PageProps> = () => (
   <Layout pageTitle="Investors">
      <section className="career">
         <div className="row center-xs">
            <div className="col-xs-12 col-sm-9 col-lg-9 text-center">
               <h1>Investors</h1>
               <p className="text-larger">If you have any investment-related questions please reach out to us at <OutboundLink href="mailto:angie@lingco.io">angie@lingco.io</OutboundLink>.</p>
               <h2>We're backed by</h2>
               <div className="backed-by-logos">
                  <img src="/img/logos/techstars.png" alt="Techstars" style={{width: 200}} />
                  <img src="/img/logos/id-ventures.png" alt="Techstars" style={{width: 200, paddingBottom: 20}} />
                  <img src="/img/logos/red-cedar-ventures.png" alt="Techstars" style={{width: 200, paddingBottom: 5}} />
               </div>
            </div>
         </div>
         <div className="row center-xs margin-top-l hidden">
            <div className="col-xs-12 col-sm-9 col-lg-4">
               <div className="job-listing">
                  <a href="/">
                     <h4>Sr. Backend Engineer</h4>
                  </a>
                  <p>Full-time, San Francisco</p>
                  <p>Curabitur vitae est ac eros convallis tincidunt ut ac massa. Morbi scelerisque neque in sem
                  efficitur, non pulvinar mauris dignissim. Sed convallis vel turpis maximus vehicula. Sed vel.
                  </p>
               </div>
               <div className="job-listing">
                  <a href="/">
                     <h4>Sr. Full Stack Engineer</h4>
                  </a>
                  <p>Full-time, San Francisco</p>
                  <p>Curabitur vitae est ac eros convallis tincidunt ut ac massa. Morbi scelerisque neque in sem
                  efficitur, non pulvinar mauris dignissim. Sed convallis vel turpis maximus vehicula. Sed vel.
                  </p>
               </div>
               <div className="job-listing">
                  <a href="/">
                     <h4>Sr. Backend Engineer</h4>
                  </a>
                  <p>Full-time, San Francisco</p>
                  <p>Curabitur vitae est ac eros convallis tincidunt ut ac massa. Morbi scelerisque neque in sem
                  efficitur, non pulvinar mauris dignissim. Sed convallis vel turpis maximus vehicula. Sed vel.
                  </p>
               </div>
            </div>
         </div>
      </section>
   </Layout>
);

export default About;
